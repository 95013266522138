<template>
  <div class="home">
    <img src="../assets/byAcuvueBanner.png" alt="" style="width: 100%">

<!--    <router-link to="/catalog">Каталог линз</router-link>-->

  </div>
</template>
<style scoped>
h2 {
  margin: 8px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
a {
  margin: 8px 12px;
}

</style>
<script>
export default {
  name: "home-page",
  data: ()=>{
    return {
      isSelectModel: true,
      isSelectLense: false,
      curModel: ''
    }
  },
  async mounted() {

  },
  methods: {
    async getData() {
    },
    async sendData() {

    }
  },
  computed: {
    // showLoader: () => {
    //   return (this.isLoading)
    //       ? Indicator.open({text: 'Пожалуйста подождите, мы проверяем информацию.', spinnerType: 'snake'})
    //       : Indicator.close()
    // }
  },
  watch: {
    'curModel' : function () {
      if (this.curModel) {
        this.isSelectLense=true
        this.isSelectModel=false
      }
      if (!this.curModel) {
        this.isSelectLense=false
        this.isSelectModel=true
      }
    }
  },
  components: {
  },
};
</script>
